import React from 'react'

import slika1 from '../images/products/novo-cream-rolls1.jpg'
import slika2 from '../images/products/novo-cream-rolls2.jpg'
import slika3 from '../images/products/novo-cream-rolls3.jpg'
import slika4 from '../images/products/novo-cream-rolls4.jpg'

class WaltzCreamRolls extends React.Component{
    render(){
        return(
            <section id="proizvodi" className="hero products-page default-page">
                <div className="container padding-0 margin-0">
                    <div className="columns margin-0 main">
                        <div className="column is-4 static-part is-left padding-0">
                            <div className="content">
                                <div className="title">WALTZ CREAM ROLLS</div>
                                <span className="zigzag"></span>
                                <p className="text"></p>
                            </div>
                        </div>{/*STATIC PART END */}
                        <div className="column is-8 content-part is-right padding-0">
                            <div className="columns is-multiline inner margin-0">
                                <div className="column is-6 single-product padding-0">
                                    <img src={slika1} alt="Waltz CreamRolls Jagoda" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={slika2} alt="Waltz CreamRolls Čokolada" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={slika3} alt="Waltz CreamRolls Lešnik" />
                                </div>
                                <div className="column is-6 single-product padding-0">
                                    <img src={slika4} alt="Waltz CreamRolls" />
                                </div>
                            </div>
                        </div>{/* PRODUCTS PART END */}
                    </div>{/* COLUMNS END */}
                </div>{/* CONTAINER END */}
            </section>/* PRODUCTS PAGE SECTION END */
        );
    }
}

export default WaltzCreamRolls